import { lazy } from "react";
const Login = lazy(() => import("../pages/login/login.page.js"));
const ForgotPassword = lazy(() => import("../pages/forgotpassword/forgotpassword.page.js"));
const ResetPassword = lazy(() => import("../pages/forgotpassword/resetpassword.page.js"));

var AuthRoutes = [
  {
    path: "/login",
    name: "Login",
    icon: "mdi mdi-account-key",
    component: Login,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    icon: "mdi mdi-account-key",
    component: ForgotPassword,
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    icon: "mdi mdi-account-key",
    component: ResetPassword,
  },
];

export default AuthRoutes;
