import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // TODO
        var currentUser = true;
        if(props.location.pathname === "/"){
          currentUser = false;
        }
        
        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }

         return <Component {...props} />; 
        }
      }
    />);
};

export default PrivateRoute;