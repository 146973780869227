import { ApolloProvider } from "@apollo/client";
import { Toaster } from "react-hot-toast";
import client from "../../common/apollo-client";
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "../../common/history";

import "./app.component.scss";
import PrivateRoute from "../../routes/private.routes";
import indexRoutes from "../../routes";
import BlankLayout from "../../layouts/blank.layout";

import { Browser } from "@syncfusion/ej2-base";

class App extends React.Component {
  render() {    
    return (
      <div className={Browser.isDevice ? "mobile" : ""}>
        <ApolloProvider client={client}>
          <Toaster position="top-right" containerStyle={{ zIndex: 999999 }} />
          <Router history={history}>
            <Switch>
              <Route exact path="/login" component={BlankLayout} />
              <Route exact path="/forgotpassword" component={BlankLayout} />;
              <Route exact path="/resetpassword" component={BlankLayout} />;
              {indexRoutes.map((prop, key) => {
                return (
                  <PrivateRoute
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              })}
            </Switch>
          </Router>
        </ApolloProvider>
      </div>
    );
  }
}

export default App;
