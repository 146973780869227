import { lazy } from "react";
import "../common/scss/style.scss";

const FullLayout = lazy(() => import("../layouts/full.layout.js"));
const BlankLayout = lazy(() => import("../layouts/blank.layout.js"));

var indexRoutes = [
    { path: "/", name: "Dashboard", component: FullLayout },
    { path: "/login", name: "Login", component: BlankLayout },
    { path: "/forgotpassword", name: "ForgotPassword", component: BlankLayout },
    { path: "/resetpassword", name: "ResetPassword", component: BlankLayout }
];

export default indexRoutes;
