import {
  createSpinner,
  hideSpinner,
  showSpinner,
} from "@syncfusion/ej2-popups";

function create(id) {
  createSpinner({
    target: document.getElementById(id),
  });
}

export function showLoading(id) {
  create(id);
  showSpinner(document.getElementById(id));
}

export function hideLoading(id) {
  hideSpinner(document.getElementById(id));
}
