import React, { useEffect } from "react";
import { showLoading } from "../../helpers/spinner";
import "./spinner.component.scss";

export const Spinner = () => {
  console.log("Spinning...");
  return (
    <div className="fallback-spinner">
      <div className="loading component-loader">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export const SpinnerInline = (props) => {
  const id = `dropdown-spinner-${Date.now()}`;

  useEffect(() => {
    showLoading(id);
  });

  return (
    <div style={{ display: "inline-block", width: "100%", height: "100%", marginTop:"50px", marginBottom:"50px" }}>
      <div className="dropdown-spinner-box" id={id}></div>
    </div>
  );
};
