export const AUTH_TOKEN = "steckr-auth-token";
export const REFRESH_TOKEN = "steckr-refresh-token";

export const LOGIN_AS_ACTIVATED = "steckr-login-as-activated";
export const LOGIN_AS_AUTH_TOKEN = "steckr-original-auth-token";
export const LOGIN_AS_REFRESH_TOKEN = "steckr-original-refresh-token";

export const USER_ABBREVIATION = "steckr-user-abbreviation";
export const USER_ROLE = "steckr-user-role";
export const ACCOUNT_ID = "steckr-user-account-id";

export const SETTINGS_ID = 1;

export const ROLES = {
  User: "user",
  Admin: "admin",
  SysAdmin: "sysadmin",
  Support: "support",
  Supplier: "supplier",
  Driver: "driver",
};
