import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./components/app/app.component";
import { Spinner } from "./components/spinner/spinner.component";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_nl from "./common/translations/nl/common.json";
import common_en from "./common/translations/en/common.json";
import common_ru from "./common/translations/ru/common.json";
import common_jp from "./common/translations/jp/common.json";

import login_nl from "./common/translations/nl/login.json";
import login_en from "./common/translations/en/login.json";
import login_ru from "./common/translations/ru/login.json";
import login_jp from "./common/translations/jp/login.json";

import testing_nl from "./common/translations/nl/testing.json";
import testing_en from "./common/translations/en/testing.json";
import testing_ru from "./common/translations/ru/testing.json";
import testing_jp from "./common/translations/jp/testing.json";

import global_nl from "./common/translations/nl/global.json";
import global_en from "./common/translations/en/global.json";
import global_ru from "./common/translations/ru/global.json";
import global_jp from "./common/translations/jp/global.json";

import store from "./helpers/ssr/store";
import SSR from "./helpers/ssr/ssr";

import { registerLicense } from "@syncfusion/ej2-base";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("sable-language") ?? "en",
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
      login: login_en,
      testing: testing_en,
      global: global_en,
    },
    nl: {
      common: common_nl, // 'common' is our custom namespace
      login: login_nl,
      testing: testing_nl,
      global: global_nl,
    },
    ru: {
      common: common_ru, // 'common' is our custom namespace
      login: login_ru,
      testing: testing_ru,
      global: global_ru,
    },
    jp: {
      common: common_jp, // 'common' is our custom namespace
      login: login_jp,
      testing: testing_jp,
      global: global_jp,
    },
  },
});

const path = require("path");

SSR.initializeStore(() => {
  store.setState("globalState", {
    pendingShipmentCount: 0,
  });
});

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VVhiQlFacl9JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1jXH9adXFWQWFdUUM="
);

require("dotenv").config({ path: path.resolve(__dirname, "../.env") });

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <I18nextProvider i18n={i18next}>
      <SSR.Provider>
        <App></App>
      </SSR.Provider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById("root")
);
